"use client";

import NavBar from "@public-sites/unified-header/dist/main";
import {
	Breakpoint,
	DropShadowWeight,
	FontWeight,
	ItemsGap,
	NavLogoKind,
	TextSize,
} from "@public-sites/unified-header/dist/types";
import { Metadata } from "next";
import { Inter, Tilt_Warp } from "next/font/google";
import { Suspense } from "react";
import Footer from "~/app/footer";
import PrivacyBanner from "~/components/privacyBanner/main";
import layoutMessages from "~/resources/messages/en-US/app/layout.json";
import ErrorMessageComponent from "./errorMessage";
import Providers from "./providers";
import ToTop from "./toTop";
import Track from "./track";

const inter = Inter({
	subsets: ["latin"],
	variable: "--font-inter",
});

const tiltWarp = Tilt_Warp({
	subsets: ["latin"],
	variable: "--font-tilt-warp",
	adjustFontFallback: false,
});

export const metadata: Metadata = {
	title: layoutMessages.layout.defaultPageTitle.value,
	description: layoutMessages.layout.defaultPageDescription.value,
	openGraph: {
		title: layoutMessages.layout.defaultPageTitle.value,
		description: layoutMessages.layout.defaultPageDescription.value,
	},
	metadataBase: new URL(process.env.NEXT_PUBLIC_BASE_SITE_URL ?? "https://localhost:3600"),
};

export default function ErrorPage() {
	return (
		<html lang="en" id="page_global_error">
			<body>
				<Suspense fallback={<div></div>}>
					<Track />
					<ToTop />
				</Suspense>
				<main
					className={`${inter.variable} ${tiltWarp.variable} font-primary text-lg h-full all:outline-offset-8`}
				>
					<div className="flex flex-col items-stretch h-screen mx-auto text-epic-black">
						<Providers>
							<Suspense fallback={<div></div>}>
								<NavBar
									siteIdentityLinkLogo={NavLogoKind.Careers}
									siteIdentityLinkTooltip={layoutMessages.layout.siteIdentityLinkTooltip.value}
									bottomBarItemsGap={ItemsGap.Small}
									mobileBreakpoint={Breakpoint.lg}
									textSize={TextSize.Small}
									itemsFontWeight={FontWeight.semiBold}
									belowBarDropShadowWeight={DropShadowWeight.Heavy}
									colors={{
										topBarBackground: "#FFFFFF",
										hoverLogoBackground: "#EAE5E1",
										logoFill: "#6B615C",
										text: "#3D2C2B",
										bottomBarSubmenuBackground: "#FFFFFF",
										bottomBarSubmenuItemText: "#6B615C",
										bottomBarBackground: "#EAE5E1",
										submenuBorder: "#EAE5E1",
										hoverLogoFill: "#C62715",
										hoverText: "#C62715",
										selectedLogoBackground: "#C62715",
										selectedHoverLogoBackground: "#C62715",
										selectedLogoFill: "#FFFFFF",
										selectedHoverLogoFill: "#FFFFFF",
										mobileNavigationButton: "#6B7280",
										mobileNavigationPaneBorder: "#334155",
										bottomBarButtonBackground: "#C62715",
										bottomBarButtonHoverBackground: "#750415",
										bottomBarButtonFocusBackground: "#750415",
										bottomBarButtonText: "#FFFFFF",
									}}
									topItems={[]}
									bottomItems={[
										{
											href: "https://epic.avature.net/Careers/Login",
											label: layoutMessages.layout.signInButtonCaption.value,
											tooltip: layoutMessages.layout.signInButtonTooltip.value,
											buttonStyle: true,
											allowedScreenSizeMode: "mobile",
										},
										{
											href: "/jobs/softwaredevelopment",
											label: layoutMessages.layout.softwareDevelopmentLinkCaption.value,
											tooltip: layoutMessages.layout.softwareDevelopmentLinkTooltip.value,
										},
										{
											href: "/lifeatepic",
											label: layoutMessages.layout.lifeAtEpicLinkCaption.value,
											tooltip: layoutMessages.layout.lifeAtEpicLinkTooltip.value,
										},
										{
											href: "/perks",
											label: layoutMessages.layout.benefitsLinkCaption.value,
											tooltip: layoutMessages.layout.benefitsLinkTooltip.value,
										},
										{
											href: "/madison",
											label: layoutMessages.layout.madisonLinkCaption.value,
											tooltip: layoutMessages.layout.madisonLinkTooltip.value,
										},
										{
											href: "/dei",
											label: layoutMessages.layout.deiLinkCaption.value,
											tooltip: layoutMessages.layout.deiLinkTooltip.value,
										},
										{
											href: "/jobs",
											label: layoutMessages.layout.jobsLinkCaption.value,
											tooltip: layoutMessages.layout.jobsLinkTooltip.value,
										},
										{
											href: "https://epic.avature.net/Careers/Login",
											label: layoutMessages.layout.signInButtonCaption.value,
											tooltip: layoutMessages.layout.signInButtonTooltip.value,
											buttonStyle: true,
											allowedScreenSizeMode: "desktop",
										},
									]}
								/>
								<ErrorMessageComponent
									backHomeCaption={layoutMessages.layout.backHomeCaption.value}
									backHomeTooltip={layoutMessages.layout.backHomeTooltip.value}
								>
									<div>
										<span>
											{"Something went wrong? You "}
											<i>{"calf"}</i>
											{" to be kidding. "}
										</span>
										<span>{layoutMessages.layout.errorMessage2.value}</span>
									</div>
								</ErrorMessageComponent>
								<PrivacyBanner
									privacyPageUrl="https://www.epic.com/privacypolicies"
									siteKey="careers"
								/>
								<Footer />
							</Suspense>
						</Providers>
					</div>
				</main>
			</body>
		</html>
	);
}
